@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import '../node_modules/@syncfusion/ej2-react-gantt/styles/material.css';


@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  /* Chrome, Safari, Edge, Opera */
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    @apply appearance-none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    @apply appearance-none;
    -moz-appearance: textfield;
  }
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.modal-box {
  overflow-y: auto;
  max-height: 80vh;
}
.scrollbar-thin::-webkit-scrollbar {
  width: 6px;
}

/* Track */
.scrollbar-thin::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: rgba(156, 163, 175, var(--tw-text-opacity));
  border-radius: 9999px;
  height: 5px;
  
}

/* Change scrollbar color */
.scrollbar-thumb-primary::-webkit-scrollbar-thumb {
  background-color: #4f46e5; /* Change this color as per your requirement */
}

.scrollbar-track-primary::-webkit-scrollbar-track {
  background-color: #e5e7eb; /* Change this color as per your requirement */
}

.custom-shadow{
  box-shadow: 0px 10px 10px 0px rgba(174,174,174,0.26);
-webkit-box-shadow: 0px 10px 10px 0px rgba(174,174,174,0.26);
-moz-box-shadow: 0px 10px 10px 0px rgba(174,174,174,0.26);
}


/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

/* Custom styles for Swiper navigation arrows */
.swiper-button-prev,
.swiper-button-next {
  width: 40px;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.swiper-button-prev:hover,
.swiper-button-next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

/* Custom styles for Swiper navigation arrow icons */
.swiper-button-prev::after,
.swiper-button-next::after {
  content: "";
  width: 20px;
  height: 20px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.swiper-button-prev::after {
  transform: rotate(225deg);
}
/* Custom styles for Swiper pagination bullets */
.swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  margin: 0 5px;
}

.swiper-pagination-bullet-active {
  background-color: rgba(0, 0, 0, 0.8);
}
